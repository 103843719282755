
import React, { useState, useEffect } from 'react';
// import { graphql } from 'gatsby';
import Layout from '../layout/layout';
import CF from '../components/commonfunctions';
import closeicon from '../assets/images/ic-close-black.svg';
import dsrecommend4 from '../assets/images/DS-recommend4.png';
import NoImage from '../assets/images/no-image-thumbnail.png';
import deckicon from '../assets/images/ic-deck.svg';
import shareicon from '../assets/images/ic-share-black.svg';
import ReactHtmlParser from "react-html-parser";
import { propTypes } from 'react-bootstrap/esm/Image';
import { useStaticQuery, graphql } from "gatsby"
import ScrollAnimation from 'react-animate-on-scroll';
import { navigate } from "@reach/router";
import Search from '../components/search';
import Comment from '../components/comment';
import moment from "moment";
import ReadMoreAndLess from 'react-read-more-less';
import ScaleLoader from "react-spinners/ScaleLoader";
import queryString from "query-string";
import noSectionImage from '../assets/images/no-image-thumbnail.png';



const SearchDetails = (props) => {

//   const data = useStaticQuery(graphql`
//  {
//     WordPress {
//       postBy(slug: "be-the-first-to-know") {
//         title
//         databaseId
//         post_extra_images {
//           roundImagePost {
//             sourceUrl
//           }
//         }
//         id
//         categories {
//           nodes {
//             name
//           }
//         }
//         story_detail {
//           postPublishDate
//           storyDataSection {
//             addShortDescriptionBlackBackgorund
//             shortDescriptionBlackBackgroundDs
//             sectionTitle
//             description
//             image {
//               sourceUrl
//               altText
//             }
//           }
//           attechDeckPdf {
//             sourceUrl
//           }
//           relatedPosts {
//             selectPost {
//               ... on WordPress_Post {
//                 title
//                 slug
//                 featuredImage {
//                   node {
//                     sourceUrl
//                   }
//                 }
//                 post_extra_images {
//                   roundImagePost {
//                     sourceUrl
//                   }
//                 }
//               }
//             }
//           }
//         }
//         comments {
//           nodes {
//             date
//             author {
//               node {
//                 name
//               }
//             }
//             content
//           }
//         }
//       }
//       posts(after: "cG9zdDoxNjY=", before: "cG9zdDoxNjY=", first: 2) {
//         edges {
//           node {
//             title
//             slug
//             uri
//           }
//         }
//       },
//     menu(id: "dGVybToxOQ==") {
//       name
//       menuItems {
//         nodes {
//           label
//           cssClasses
//           menu_data {
//             externalLink
//             sectionId
//             svg {
//               sourceUrl
//             }
//           }
//         }
//       }
//     }
//     }
//   }
//   `)
//   const [isSticky, setSticky] = useState(false);
//   const [isOpen, setIsopen] = useState(false);
//   const [disclamier, setDisclamier] = useState(true);
//   const [scrollOffset, setOffset] = useState(0);
//   const [details, setDetails] = useState({})


//   const handleScroll = (e) => {
//     var headerH = document.querySelector('header') ? document.querySelector('header').offsetHeight : "";
//     setSticky(window.scrollY > headerH ? true : false);
//   };
//   useEffect(() => {
//     let token = CF.getItem('token');
//     /*  if (!token) navigate('/login'); */

//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', () => handleScroll);
//     };
//   }, []);
//   useEffect(() => {
//     if (props.location.search) {
//       window.scrollTo(0, 0)
//       getDetails(props.location.search)
//     }
//   }, [props.location])
//   const getDetails = async (data) => {
//     let url = queryString.parse(data)
//     let wordpress = `https://php71.indianic.com/xumusic/wp-json/wp/v2/posts?slug=${url.slug}`;
//     // let wordpress = `https://php71.indianic.com/xumusic/wp-json/wp/v2/high_notes?slug=${url.id}`;
//     let response = await CF.callApi(wordpress, '', 'get', true);
//     if (response.data && response.data[0] && response.data[0])
//       setDetails(response.data[0])
//   }
//   const settingDisclamier = () => {
//     setDisclamier(false);
//     CF.setItem('disclamier', false)
//   }
//   const handleCategory = (e, label) => {
//     if (label == "Search") {
//       setIsopen(!isOpen)
//     }
//     else if (label == "Resources") {
//       navigate('/resources')
//     }
//     else if (label == 'Subscribe') {
//       e.preventDefault();
//       e.stopPropagation();
//       const scrollSec = document.querySelector(e.target.hash);
//       const headerOffset = document.getElementsByClassName("category-menu-outer") && document.getElementsByClassName("category-menu-outer")[0].clientHeight;
//       const offsetH = (scrollSec.offsetTop - (!isSticky ? 108 + headerOffset : 108));
//       setOffset(offsetH)
//       window.scrollTo({ behavior: "smooth", top: offsetH });
//     }
//     else if (label === 'Show All') navigate('/showall')
//     else if (label === "Category 1") navigate('/category1')
//     else if (label === "Category 2") navigate('/category2')
//     else if (label === "Category 3") navigate('/category3')
//   }
//   const pageTitle = details && details.title && details.title.rendered;
//   const image = details && details.acf && details.acf.round_image_post
//   const categories = details && details.categories_names;
//   const postPublishDate = details && details.acf && details.acf.post_publish_date;
//   const storyDataSection = details && details.acf && details.acf.story_data_section;
//   const pdfSourceUrl = details && details.acf && details.acf.attech_deck_pdf;
//   const relatedPosts = details && details.acf && details.acf.related_posts;
//   const comments = data.WordPress.postBy.comments && data.WordPress.postBy.comments.nodes;
//   const postsSider = data.WordPress.posts && data.WordPress.posts.edges;
//   let menuArr = data.WordPress.menu && data.WordPress.menu.menuItems && data.WordPress.menu.menuItems.nodes;
//   let databaseId = data.WordPress && data.WordPress.postBy && data.WordPress.postBy.databaseId;
//   let recommended_section_title = details && details.acf && details.acf.recommended_section_title
  return (
    <React.Fragment>
      {/* {!Object.keys(data).length || (data.WordPress && data.WordPress.postBy && data.WordPress.postBy.length === 0) ?
        <div className="product-loader"><ScaleLoader color={'#141618'} size={150} /></div> :
        <Layout uma="inner-page" headerColor="header-DS" title="DS" seoTitle={'Data story details'}>
          <div className={`category-menu-outer ds-category-menu-outer ${isSticky ? 'header-category-fixed' : ''}`}>
            <div className="container"> */}
              {/********************* Category Menu Start *********************/}
              {/* <nav className="category-menu">
                <ul>
                  {menuArr && menuArr.length ? menuArr.map((menu, key) => {
                    return (<li className="seven-category" key={key} onClick={(e) => handleCategory(e, menu.label)}>
                      <a id="responsive-menu-trigger" href={menu && menu.label === 'Subscribe' ? '#conatctus' : menu.label === "Search" && "#"}>
                        <span className="category-img">
                          <img src={menu && menu.menu_data && menu.menu_data.svg && menu.menu_data.svg.sourceUrl} alt={menu.label} />
                        </span>
                        <span className="category-name">{menu.label}</span>
                      </a>
                    </li>
                    )
                  }) : null}
                </ul>
              </nav> */}
              {/********************* Category Menu End *********************/}
            {/* </div>
          </div>
          <Search name="ds-details" />
          <section className="search-view">
            <div className="container">
              <div className="serach-for-title">
                <span className="search-for">Saerch result for</span>
                <h6 className="search-title">Artists</h6>
              </div>
              <div className="saerch-result-listing">
                <div className="search-result">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="saerch-result-img">
                        <img src={noSectionImage} alt="music" />
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="saerch-result-content">
                        <h6>What is Lorem Ipsum?</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="search-result">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="saerch-result-img">
                        <img src={noSectionImage} alt="music" />
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="saerch-result-content">
                        <h6>What is Lorem Ipsum?</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="search-result">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="saerch-result-img">
                        <img src={noSectionImage} alt="music" />
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="saerch-result-content">
                        <h6>What is Lorem Ipsum?</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="search-result">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="saerch-result-img">
                        <img src={noSectionImage} alt="music" />
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="saerch-result-content">
                        <h6>What is Lorem Ipsum?</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="search-result">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="saerch-result-img">
                        <img src={noSectionImage} alt="music" />
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="saerch-result-content">
                        <h6>What is Lorem Ipsum?</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


        </Layout>
      } */}
    </React.Fragment>
  )
}
export default SearchDetails
